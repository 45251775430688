import React, { Component } from 'react';
import GenericPage from '../components/genericpage';
import data from '../data/privacy.json'


const PrivacyPolicy = () => {
  return (
    <GenericPage PageData = {data} />
  )
}

export default PrivacyPolicy